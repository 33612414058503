import React from "react"
import {Link, HeadFC, graphql} from "gatsby"
import Seo from "../containers/Seo";
import Layout from "../containers/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <div className="container">
          <div className="warning-bg text-center mb-5 mt-5">
            お探しのページが見つかりませんでした
          </div>

          <div className="text-center">
            <Link to="/" className="btn btn-outline-primary">トップへ戻る</Link>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}: {data: any}) => (
  <Seo data={data}>
    <title id="title">Not Found | {data.site.siteMetadata.title}</title>
    <meta id="description" name="description" content={"お探しのページが見つかりませんでした。"} />
  </Seo>
);

export default NotFoundPage